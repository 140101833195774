import request from '@http';

// 获取智能门锁列表
export function getDoorListApi(params) {
    return request({
        method: 'GET',
        url: 'api/gzf/lock/an/lock/list',
        params,
    })
}

// 2022/04/01 王江毅 获取房源列表
export function getBaseinfoApartment(params) {
    return request({
        method: 'GET',
        // url: 'api/gzf/baseinfo/apartment/',
        url: `/api/gzf/baseinfo/apartment/bycummunity/page`,
        params
    })
}

// 2022/04/01 王江毅 发送门锁密码
export function dcLock(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}`,
        params
    })
}

//2022/04/06 王江毅 清空门锁密码 
export function lockClear(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}/clear`,
        params
    })
}

// 2022/04/01 王江毅 获取房间水电读数
export function hydropower(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${uuid}`
    })
}

//2022/04/05 王江毅 获取门锁密码列表
export function getLocks(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/locks/${params.uuid}`,
        params
    })
}

//2022/04/08 王江毅 修改水电表号 api/gzf/dc/hydropower/{uuid}/num
export function setHydropowerNum(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/num`,
        params
    })
}

// 2022/04/12 王江毅 设置密码
export function lockTemporary(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/lock/${params.uuid}/temporary`,
        params
    })
}

// 2022/04/13 王江毅 水电表抄表回显
export function hydropowerBill(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/bill`,
        params
    })
}

// 2022/04/13 王江毅 水电表抄表
export function hydropowerSettlement(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/settlement`,
        params
    })
}

// 2022/04/14 王江毅 水电表抄表数据列表 /api/gzf/dc/hydropower/{uuid}/list
export function getHydropowerList(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/list`,
        params
    })
}
// 2022/04/20 王江毅 水电表关闸 api/gzf/dc/hydropower/{uuid}/close
export function getHydropowerClose(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/${params.uuid}/close`,
        params
    })
}

// 2022/04/20 王江毅 密码不可用
export function passDel(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/pass/${uuid}/del`
    })
}

//  2022/05/27 王江毅 开锁记录
export function openLocksLog(uuid,params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/open/locks/${uuid}`,
        params
    })
}

// 2022/09/05 王江毅 根据水电表记录查账单
export function byHyUuid(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/paybill/payBill/byHyUuid`,
        params
    })
}

// 2022/10/08 王江毅 添加临时密码获取权限
export function getMenu(uuid) {
    return request({
        method: 'GET',
        url: `/api/gzf/system/menu/password/save/${uuid}`
    })
}
// 2023/03/09 王江毅 换表信息
export function getDeviceInfo(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/exchange/device/info`,
        params
    })
}
// 2023/03/09 王江毅 换表提交
export function exchangeDevice(data) {
    return request({
        method: 'POST',
        url: `/api/gzf/dc/exchange/device`,
        data,
    })
}

// 2023/03/09 王江毅 换锁提交
export function deviceLock(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/exchange/device/lock`,
        params,
    })
}

// 2023/08/18 张晓瑜 设置密码(iotTag = 1)
export function iotTagLockTemporary(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/bzf/lock/online/password`,
        params
    })
}

// 2023/09/18 王江毅 抄表记录数据异常时提交修改
export function dcHydropowerUpdate(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/dc/hydropower/update`,
        params
    })
}


//  2023-3-13-陈守亮-操作记录
export function tableoperatingRecordapi(params) {
    return request({
        method: 'GET',
        url: `/api/gzf/lock/operating/log/api/web/list`,
        params
    })
}